@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins-light.eot');
    src: url('../../fonts/poppins-light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/poppins-light.woff2') format('woff2'),
    url('../../fonts/poppins-light.woff') format('woff'),
    url('../../fonts/poppins-light.svg#poppins-light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins-regular.eot');
    src: url('../../fonts/poppins-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/poppins-regular.woff2') format('woff2'),
    url('../../fonts/poppins-regular.woff') format('woff'),
    url('../../fonts/poppins-regular.svg#poppins-regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins-medium.eot');
    src: url('../../fonts/poppins-medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/poppins-medium.woff2') format('woff2'),
    url('../../fonts/poppins-medium.woff') format('woff'),
    url('../../fonts/poppins-medium.svg#poppins-medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins-semibold.eot');
    src: url('../../fonts/poppins-semibold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/poppins-semibold.woff2') format('woff2'),
    url('../../fonts/poppins-semibold.woff') format('woff'),
    url('../../fonts/poppins-semibold.svg#poppins-semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/poppins-bold.eot');
    src: url('../../fonts/poppins-bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/poppins-bold.woff2') format('woff2'),
    url('../../fonts/poppins-bold.woff') format('woff'),
    url('../../fonts/poppins-bold.svg#poppins-bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
// Font-size
$fontDefault: 1.8;
$fontTable:1.6;
$fontButton:1.4;

// Line-height
$lineHeightDefault: 2.5rem;
$lineHeightTable: normal;

@mixin fontButton {
    font-weight: 500;
    line-height: 2.2rem;
}

@mixin font{
    font-family: 'Poppins';
}
@mixin fontTable($size: $fontTable, $lineHeight: $lineHeightTable){
    font-size: $size + rem;
    line-height: $lineHeight;
}
@mixin fontIcon($sizeValue: 2.2, $iconWeight:  300) {
    font-family: "Font Awesome 5 Pro";
    font-weight: $iconWeight;
    font-size: $sizeValue + rem;
}

@mixin fontSmaller {
    font-size: 1.4rem;
    line-height: 2.2rem;
    @media only screen and (max-width: 480px) {
        font-size: 1.3rem;
    }
}
// ** fontDefault **  font-size($sizeValue), line-height($lineHeight), color($fontColor), font-weight($fontWeight)
@mixin fontDefault(
    $sizeValue: $fontDefault,
    $lineHeight: $lineHeightDefault,
    $color: null,
    $weight: null,
    $transform: null,
    $style: null,
    $fontStyle:null) {


    @if ($sizeValue != null) {
        font-size: $sizeValue + rem;
    }
    /* font-size */
    @if ($lineHeight != null) {
        line-height: $lineHeight ;
    }
    @if ($color != null) {
        color: $color;
    }
    @if ($weight != null) {
        font-weight: $weight;
    }
    @if ($transform != null) {
        text-transform: $transform;
    }
    @if ($style != null) {
        text-decoration: $style;
    }
    @if ($fontStyle != null) {
        font-style: $fontStyle;
    }
}
@mixin fontAdditional(
    $color: null,
    $weight: null,
    $transform: null,
    $style: null,
    $fontStyle:null){
    text-transform: $transform;
    font-style: $fontStyle;
    text-decoration: $style;

    /* color */
    @if ($color == "default") {
        color: $colorFontDefault;
    }@else {
        color: $color;
    }

    /* font-weight */
    @if ($weight == "default") {
        font-weight: normal;
    }@else{
        font-weight: $weight;
    }
}
@mixin specialFont {
    @include font;
    font-weight: normal;
    text-align: left;
}


body {
    @include fontDefault($fontDefault, $lineHeightDefault);
    @include fontAdditional(default, default);
    @include font;
    @include breakpoint(size1024px){
        word-break: break-word;
        hyphens: auto;
        @include fontDefault(1.6);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include specialFont;
    text-align: left;
    color: $colorPrimary;
    word-break: break-word;
    hyphens: auto;
}
@mixin title($sizeH1) {
    @if $sizeH1 == homeTitleSlider {
        @include fontDefault(7.9, 7.2rem);
        @include fontAdditional($colorWhite, 600);
        text-transform: uppercase;
    } @else if $sizeH1 == defaultTitle{
        @include fontDefault(3, 3.6rem);
        @include fontAdditional($weight: 600);
        text-align: left;
    }@else if $sizeH1 == page-title-wrapper{
        @include fontDefault(3.7, 4.5rem);
        @include fontAdditional(inherit, 600);
        text-align: center;
    }
}
h1 {
    @include title(defaultTitle);
    margin-bottom: 0;
    @include breakpoint(size768px){
        font-size: 3rem;
        line-height: 1.2;
        margin: 0 0 2rem 0;
    }
}

h2 {
    @include fontDefault(2.4, 2.4rem);
    &:first-of-type{
        padding-top: 0;
    }
    @include breakpoint(size768px){
        font-size: 2rem;
        line-height: 2rem;
    }
}
h3 {
    @include fontDefault(2, 2rem);
    font-weight: 600;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
}
h4 {
    @include fontDefault(2, 2rem);
    font-weight: 600;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
    @media only screen and (max-width: 480px) {
        padding-bottom: 0.9rem;
    }
}

a {
    color: $colorPrimary;
    text-decoration: none;
}

b,
strong {
    font-weight: 600;
}

ol,
ul {
    margin: 0;
    padding: 0 0 0 0;
    list-style: none;
}
p{
    @include fontAdditional($color: $colorPrimary);
    margin-bottom: .8rem;
}
small, .small{
    font-size: 1.2rem;
}