// width
$maxWidth: 192rem;
$contentWidth: 156rem;
$mediumContent: 105rem;
$smallContent: 65rem;

// breakpoints
$maxBreakpoint: 1500px;
$mediumMaxBreakpoint: 1200px;
$tabletLandscape: 1024px;
$tabletPortrait: 768px;
$phone: 480px;
$smallPhone: 360px;

// images
$imageDir: "../../images/";
// images
$imageDirResp: "../images/";
// icons
$iconDir: "../../images/icons/";

// Specifications
// Personalized Primary colors
$colorPrimary: #3D3D3D;
$colorSecondary: #A7C50D;
$colorFontDefault: $colorPrimary;
$colorLightSecondary: rgba($colorSecondary, .4);
$colorBeige: #F9F7F1;

// Personalized colors
$colorBorder: #aaa;
$colorInputBorder: #484C5261;
$colorButton: #FFFDF7;
$colorPlaceholder:#333;

// Grey
$colorLighterGrey: #EBEBEB;
$colorLightGrey: #ABABAB;
$colorGrey: #EEEFEF;
$colorDarkGrey: #707070;
$colorDarkGrey2: #484C52;
$colorSwatchGrey: #D5D5D5;

// Default colors
$colorError: #E63636;
$colorSuccess: #A7C50D;
$colorSuperSuccess: #408140;
$colorInfo: #F7B234;
$colorWarning: #F7B234;
$colorWhite: #fff;
$colorBlack: #000;
$colorRed: #E63636;




// icons: use fontawesome pro unicode - leave empty for no icon
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f2ed";
$iconEdit: "\f040";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconToggle: "\f0c9";
$iconSearch: "\f002";
$iconSortArrow: "\f0dd";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";
$iconSearch: "\f002";
$iconCart: "\f07a";
$iconBag: "\f290";
$iconPlus: "\f067";
$iconMinus: "\f068";
$iconEye: "\f06e";
$iconPhone: "\f095";
$iconLetter: "\f2b6";
$iconStar: "\f005";


