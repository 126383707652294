$rounded-corners: false;
$buttonPadding: 1.2rem 3rem;

@mixin outerContent($mobile: null){
  max-width: $contentWidth;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @if ($mobile == nopadding) {
    @include breakpoint(size768px){
      padding: {
        left: 0;
        right: 0;
      };
    }
  }
}
@mixin innerContent{
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 0 3rem;
  @include breakpoint(size1500px){
    max-width: $mediumMaxBreakpoint;
  }
  @include breakpoint(size1200px){
    max-width: $tabletLandscape;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include breakpoint(size1024px){
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@mixin smallContent{
  max-width: 127rem;
  width: 100%;
  margin: 0 auto;
  @include breakpoint(size1500px){
    max-width: $tabletLandscape;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include breakpoint(size1200px){
    max-width: $tabletLandscape;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include breakpoint(size1024px){
    max-width: $tabletPortrait;
    width: 100%;
  }
}

@mixin transition($all: all, $effect: ease, $duration: .2s) {
  -webkit-transition: $all $effect $duration;
  -o-transition: $all $effect $duration;
  transition: $all $effect $duration;
}

@mixin flexDefault {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin flex( $justify: null, $align: null, $direction: null,$wrap: null, $flex: true, $gap: false) {
  @if $flex {
    @include flexDefault;
  }@else{
  }
  @if $gap {
    gap: $gap;
  }@else{
  }
  @if $justify == centery{
    align-items: center;
    justify-content: center;
  } @if $justify == flex-endy{
    align-items: flex-end;
    justify-content: flex-end;
  } @if $justify == flex-starty{
    align-items: flex-start;
    justify-content: flex-start;
  } @if $justify == space-betweeny{
    align-items: space-between;
    justify-content: space-between;
  } @else{
    justify-content: $justify;
    align-items: $align;
  }

  flex-direction: $direction;
  @if $wrap == true {
    flex-wrap: wrap;
  }@else if $wrap == false{
    flex-wrap: nowrap;
  }@else {

  }
}
@mixin flexWrap($wrap: true, $flex: flex) {
  @if ($flex == flex) {
    @include flexDefault;
  }
  flex-wrap: $wrap;
}
@mixin flexAlign($directionH: null, $directionV: null, $flex: null) {
  @if ($flex == flex) {
    @include flexDefault;
  }
  align-items: $directionV;
  justify-content: $directionH;
}
@mixin flexDirection($direction: null, $flex: null) {
  @if ($flex == flex) {
    @include flexDefault;
  }
  flex-direction: $direction;
}

@mixin overlay($directionV: top, $directionH: left, $width: 100%, $height: 100%, $position: absolute, $right: false, $bottom: false, $top: false, $left: false, $z-index: null){
  z-index: $z-index;
  position: $position;
  height: $height;
  width: $width;
  @if $directionV == top {
    top: 0;
  } @else if $directionV == bottom {
    bottom: 0;
  } @else if $top {
    top: $directionV;
  } @else if $bottom {
    bottom: $directionV;
  } @else {
    top: $directionV;
  }

  @if $directionH == left {
    left: 0;
  } @else if $directionH == right {
    right: 0;
  } @else if $left {
    left: $directionH;
  } @else if $right {
    right: $directionH;
  } @else {
    left: $directionH;
  }
}

/* default :1px solid $colorBorder */
@mixin border($size: 2px, $color: $colorBorder, $weight: solid, $position: null) {
  @if $position == top {
    border-top: $size $weight $color;
  } @else if $position == bottom {
    border-bottom: $size $weight $color;
  } @else if $position == left {
    border-left: $size $weight $color;
  } @else if $position == right {
    border-right: $size $weight $color;
  } @else {
    border: $size $weight $color;
  }
}
@mixin boxShadow($hLength: null, $vLength:null, $blur: null, $spread: null, $color: null){
  -webkit-box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
  -moz-box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
  box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
}
