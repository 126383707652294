@charset "UTF-8";
/* default :1px solid $colorBorder */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}

html, body {
  min-height: 100vh;
}

html {
  font-size: 62.5%;
}

img {
  max-width: 100%;
  height: auto;
  line-height: normal;
}

body {
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: #3D3D3D;
  color: #fff;
}

body.cms-no-route .columns {
  max-width: 156rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 13rem;
  padding-top: 4.5rem;
}

body.cms-no-route .columns a {
  font-weight: bold;
}

.column-main {
  position: relative;
}

@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/poppins-light.eot");
  src: url("../../fonts/poppins-light.eot?#iefix") format("embedded-opentype"), url("../../fonts/poppins-light.woff2") format("woff2"), url("../../fonts/poppins-light.woff") format("woff"), url("../../fonts/poppins-light.svg#poppins-light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/poppins-regular.eot");
  src: url("../../fonts/poppins-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/poppins-regular.woff2") format("woff2"), url("../../fonts/poppins-regular.woff") format("woff"), url("../../fonts/poppins-regular.svg#poppins-regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/poppins-medium.eot");
  src: url("../../fonts/poppins-medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/poppins-medium.woff2") format("woff2"), url("../../fonts/poppins-medium.woff") format("woff"), url("../../fonts/poppins-medium.svg#poppins-medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/poppins-semibold.eot");
  src: url("../../fonts/poppins-semibold.eot?#iefix") format("embedded-opentype"), url("../../fonts/poppins-semibold.woff2") format("woff2"), url("../../fonts/poppins-semibold.woff") format("woff"), url("../../fonts/poppins-semibold.svg#poppins-semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/poppins-bold.eot");
  src: url("../../fonts/poppins-bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/poppins-bold.woff2") format("woff2"), url("../../fonts/poppins-bold.woff") format("woff"), url("../../fonts/poppins-bold.svg#poppins-bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-size: 1.8rem;
  /* font-size */
  line-height: 2.5rem;
  /* color */
  color: #3D3D3D;
  /* font-weight */
  font-weight: normal;
  font-family: 'Poppins';
}

@media (max-width: 1024px) {
  body {
    word-break: break-word;
    hyphens: auto;
    font-size: 1.6rem;
    /* font-size */
    line-height: 2.5rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins';
  font-weight: normal;
  text-align: left;
  text-align: left;
  color: #3D3D3D;
  word-break: break-word;
  hyphens: auto;
}

h1 {
  font-size: 3rem;
  /* font-size */
  line-height: 3.6rem;
  /* color */
  /* font-weight */
  font-weight: 600;
  text-align: left;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 3rem;
    line-height: 1.2;
    margin: 0 0 2rem 0;
  }
}

h2 {
  font-size: 2.4rem;
  /* font-size */
  line-height: 2.4rem;
}

h2:first-of-type {
  padding-top: 0;
}

@media (max-width: 768px) {
  h2 {
    font-size: 2rem;
    line-height: 2rem;
  }
}

h3 {
  font-size: 2rem;
  /* font-size */
  line-height: 2rem;
  font-weight: 600;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 2rem;
  /* font-size */
  line-height: 2rem;
  font-weight: 600;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) {
  h4 {
    padding-bottom: 0.9rem;
  }
}

a {
  color: #3D3D3D;
  text-decoration: none;
}

b,
strong {
  font-weight: 600;
}

ol,
ul {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}

p {
  /* color */
  color: #3D3D3D;
  /* font-weight */
  margin-bottom: .8rem;
}

small, .small {
  font-size: 1.2rem;
}

@media (max-width: 1500px) {
  .step-content table.table-checkout-shipping-method tr {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap;
  }
  .step-content table.table-checkout-shipping-method td.col {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0;
  }
  .step-content table.table-checkout-shipping-method td.col > span {
    padding-right: .5rem;
  }
  .step-content table.table-checkout-shipping-method td.col > * {
    margin-top: 0;
  }
}

@media (max-width: 1200px) {
  .header-main-account i, .header-main-hotline i, .header-main-hotline i {
    margin-bottom: 0;
  }
  .header-main-account a span {
    display: none;
  }
  .header-main-hotline li:nth-of-type(2) {
    display: none;
  }
  .header-main-hotline a {
    font-size: 1.6rem;
  }
  .header-main-hotline i {
    font-size: 2.4rem;
  }
  .header-main-shop i {
    display: none;
  }
  .header-main-shop li a {
    font-size: 0;
  }
  .header-main-shop li a:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    font-size: 2.2rem;
    content: "";
  }
  .header-main .minicart-wrapper > a .text {
    display: none;
  }
  .header-main .minicart-wrapper > a:before {
    margin-bottom: 0;
  }
  .page-title h2 {
    line-height: 5rem;
  }
  .navigation ul li.level0 > a {
    padding: 1.8rem 2rem;
    padding-left: 0;
    padding-bottom: 4rem;
  }
}

@media (max-width: 1024px) {
  .page-main {
    margin-top: 8.3rem;
  }
  .checkout-index-index .page-main {
    margin-top: 0;
  }
  .checkout-index-index header .header-main-container.bottom {
    display: none;
  }
  .inner-top-container {
    width: 100%;
  }
  .inner-top-container.left {
    padding-bottom: 1rem;
  }
  .header-top-container {
    display: none;
  }
  .header-main-language, .header-main-search {
    display: none !important;
  }
  .header-main-logo {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-main-logo .logo img {
    max-height: 5rem;
    width: auto;
  }
  .header-main-container {
    align-items: center;
  }
  .header-main-container.bottom {
    position: relative;
  }
  .header-main-container .right {
    padding-bottom: 0;
  }
  .header-main-container .right .section {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-main-container .right .section.header-main-navigation {
    padding-right: 0;
  }
  .header-bottom {
    display: none;
  }
  .sticky .header-main-container.bottom .block-search .block-content {
    bottom: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .sticky .header-main-container .right {
    padding-bottom: 0;
  }
  .sticky + main {
    padding-top: 9rem;
  }
  .page-wrapper {
    left: 0;
    position: relative;
    -webkit-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    transition: all ease 0.2s;
  }
  .details-wrapper {
    justify-content: space-between;
  }
  .catalog-product-view .column {
    margin-top: 0;
  }
  .page-layout-2columns-left .columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .category-page-header .page-title-wrapper {
    vertical-align: center;
  }
  .category-page-header .page-title-wrapper h1 {
    line-height: normal;
    margin-bottom: 0;
  }
  .filter {
    padding: 0;
  }
  .inner-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  footer .inner-container {
    border-bottom: 1px solid #707070;
    padding: 5rem 0;
  }
  .footer-advantages, .footer-main, .footer-bottom, .footer-payment {
    padding: 0 1.5rem;
    border: 0 solid #aaa;
  }
  .footer-advantages ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    gap: .4rem;
  }
  .footer-advantages ul li {
    width: calc(50% - .2rem);
  }
  .footer-main .section {
    width: 50%;
    padding-bottom: 4rem;
  }
  .footer-main .section.footer-main-shop {
    width: 100%;
    padding-bottom: 0;
  }
  .footer-main-products, .footer-main-links {
    margin-top: 4rem;
  }
  .footer-payment .inner-container > ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .footer-payment .inner-container > ul > li {
    justify-content: flex-start;
  }
  .footer-payment .inner-container > ul > li:last-child {
    width: 100%;
  }
  .footer-payment .inner-container > ul > li ul {
    width: 100%;
  }
  .footer-payment .inner-container > ul > li ul li {
    white-space: nowrap;
  }
  .to-top.active {
    right: 1.5rem;
    bottom: 1.5rem;
  }
  .breadcrumbs .item {
    display: none;
  }
  .breadcrumbs .item:first-child, .breadcrumbs .item:nth-last-child(2), .breadcrumbs .item:last-child {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .header-main-logo .logo {
    margin-left: 0;
    text-align: center;
    max-width: 90%;
  }
  .header-main-shop li {
    padding: 0;
  }
  .header-main-search {
    width: 75%;
    margin-right: 0;
  }
  .header-main-hotline li:nth-of-type(2) {
    display: none;
  }
  .header-main-hotline, .header-main-account, .header-main-cart {
    padding: 0 0 0 1.5rem;
  }
  .header-main-navigation {
    padding-right: 0 !important;
  }
  .inner-main-container {
    width: auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .inner-main-container.right {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .block-title {
    padding-top: 5rem;
  }
  .block-title h2 {
    font-size: 2.8rem;
    /* font-size */
    line-height: normal;
    padding-bottom: 2rem;
    margin-bottom: 0;
  }
  .block-title h2 span {
    width: auto;
    display: inline-block;
  }
  .toolbar-products {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap;
  }
  .toolbar .limiter, .toolbar .sorter {
    width: auto;
  }
  .toolbar .limiter label, .toolbar .sorter label {
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }
  .toolbar .limiter .control, .toolbar .sorter .control {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .toolbar .limiter select, .toolbar .sorter select {
    width: 100%;
    height: 4.3rem;
    font-size: 1.5rem;
  }
  .toolbar .sorter {
    margin-left: 0;
    padding-left: 0;
  }
  .toolbar .limiter {
    order: 1;
  }
  .page-layout-2columns-left .columns, .page-layout-2columns-right .columns {
    width: 100%;
  }
  .page-layout-2columns-left .columns .column, .page-layout-2columns-right .columns .column {
    width: 100%;
  }
  .inner-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .product-item-price {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .product-item-price .price-box {
    width: 100%;
  }
  .product-item-price .price-details {
    margin-top: 1rem;
  }
  .contact .field.comment .control {
    height: auto;
  }
  .contact .fieldset > div {
    width: 100%;
  }
  .limiter-text {
    white-space: nowrap;
  }
  .fotorama__stage__frame img.fotorama__img {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  .checkout-agreement, .billing-address-same-as-shipping-block {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  .checkout-agreement label, .billing-address-same-as-shipping-block label {
    font-size: 1.6rem;
  }
  .checkout-agreement input, .billing-address-same-as-shipping-block input {
    margin-top: .5rem;
  }
  .filter {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .filter-options {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .filter-options-item {
    margin-top: 2rem;
    margin-left: 0;
    background: #fff;
    border-radius: .4rem;
    border: 1px solid #aaa;
    max-width: 100%;
  }
  .filter-options-content {
    position: relative;
    padding-top: 0;
  }
  .filter-content {
    padding-left: 0;
  }
  .filter-title {
    width: 100%;
    padding-bottom: 2rem;
  }
  footer .inner-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .footer-main .section {
    width: 100%;
  }
  .footer-main-shop {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .footer-main-shop ul {
    width: 100%;
    padding-right: 0;
    padding-bottom: 2.4rem;
  }
  .footer-main-shop img {
    width: 100%;
  }
  .footer-bottom-socialmedia, .footer-bottom-info {
    max-width: 100%;
  }
  .footer-bottom-socialmedia {
    padding-bottom: 2rem;
  }
  .footer-advantages ul li {
    width: 100%;
  }
  .footer-payment .inner-container {
    padding: 3rem 0;
  }
  .footer-payment .inner-container ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .footer-payment .inner-container li {
    padding-bottom: 4rem;
    width: 100%;
    gap: 0;
  }
  .footer-payment .inner-container li:last-child {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .footer-payment .inner-container li ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap;
  }
  .footer-payment .inner-container li ul li {
    width: auto;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  #shipping-new-address-form > .field {
    width: 100%;
  }
  .page-main .actions-toolbar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .block-title h2 {
    width: 100%;
  }
  .breadcrumbs .item {
    display: none;
  }
  .breadcrumbs .item:last-child strong {
    font-size: 0;
  }
  .breadcrumbs .item:first-child, .breadcrumbs .item:nth-last-child(2), .breadcrumbs .item:last-child {
    display: inline-block;
  }
  .breadcrumbs .item:nth-last-child(2) a:before, .breadcrumbs .item:nth-last-child(2) a:after {
    display: inline-block;
  }
  .filter-value {
    width: 100%;
  }
  .filter-current-subtitle {
    width: 100%;
    text-align: center;
    padding-right: 0;
    padding-bottom: 2rem;
  }
  .filter-current-wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .filter-current-wrap .item {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.5rem;
  }
  .filter-current-wrap .item .action {
    width: auto;
  }
  .filter-current-wrap .items {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-flowing-text {
    display: none;
  }
  .footer-copyright {
    border: 0 solid #aaa;
  }
  .footer-bottom-info li {
    width: 100%;
  }
  .toolbar .limiter select, .toolbar .sorter select {
    width: auto;
  }
}
