// https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/

$breakpoints: (
        size#{$smallPhone}:  ( max-width: $smallPhone ),
        size#{$phone}:  ( max-width: $phone ),
        size#{$tabletPortrait}:  ( max-width: $tabletPortrait ),
        size#{$tabletLandscape}:  ( max-width: $tabletLandscape ),
        size#{$mediumMaxBreakpoint}:  ( max-width: $mediumMaxBreakpoint ),
        size#{$maxBreakpoint}:  ( max-width: $maxBreakpoint )
) !default;

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}




