*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}
html, body{
  min-height: 100vh;
}
html {
  font-size: 62.5%;
}
img{
  max-width: 100%;
  height: auto;
  line-height: normal;
}
body {
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


::selection {
  background-color: $colorPrimary;
  color: $colorWhite;
}

body.cms-no-route .columns{
  @include outerContent;
  margin-bottom: 13rem;
  padding-top: 4.5rem;
  a {
    font-weight: bold;
  }
}
.column-main {
  position: relative;
}
$animate: all 0.2s ease-in-out;